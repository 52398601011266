.AppHeader,
.GridInput,
.GridOutput {
  grid-gap: 2px;
  padding: 4px;
}

.App,
.GridInput-item-similar {
  background-color: #D1F5BB !important;
}

.AppHeader-item-active,
.GridInput {
  background-color: #4CBD51 !important;
}

.GridInput-item-active {
  background-color: #079565 !important;
}

.AppHeader-item,
.GridInput-item {
  background-color: #fff;
  color: #079565;
}

.AppHeader-item-active,
.GridInput-item-active {
  color: #fff;
}

.AppHeader-item-logo {
  background-color: transparent;
  color: #4CBD51;
  font-size: 0.75em;
}

.GridOutput {
  background-color: #fff;
}

.GridOutput-item-char {
  font-size: 1.2em;
}

.GridOutput-item-script {
  color: #999;
}
