.AppHeader {
  display: grid;
  grid-column: 2;
  grid-column-end: 3;
  grid-row: 2;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(11, 1fr);
}

.AppHeader-item {
  align-items: center;
  display: grid;
  justify-items: center;
}

.AppHeader-item-logo {
  grid-row: 11;
}

.AppHeader-item-HIR {
  grid-row: 10;
}

.AppHeader-item-KAT {
  grid-row: 9;
}

.AppHeader-item-ROM {
  grid-row: 8;
}
