.GridInput {
  display: grid;
  grid-column: 1;
  grid-row: 2;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(11, 1fr);
}

.GridInput-item {
  align-items: center;
  display: grid;
  justify-items: center;
}

.GridInput-item-col-1 {
  grid-column: 1;
}

.GridInput-item-col-2 {
  grid-column: 2;
}

.GridInput-item-col-3 {
  grid-column: 3;
}

.GridInput-item-col-4 {
  grid-column: 4;
}

.GridInput-item-col-5 {
  grid-column: 5;
}

.GridInput-item-row-1 {
  grid-row: 1;
}

.GridInput-item-row-2 {
  grid-row: 2;
}

.GridInput-item-row-3 {
  grid-row: 3;
}

.GridInput-item-row-4 {
  grid-row: 4;
}

.GridInput-item-row-5 {
  grid-row: 5;
}

.GridInput-item-row-6 {
  grid-row: 6;
}

.GridInput-item-row-7 {
  grid-row: 7;
}

.GridInput-item-row-8 {
  grid-row: 8;
}

.GridInput-item-row-9 {
  grid-row: 9;
}

.GridInput-item-row-10 {
  grid-row: 10;
}

.GridInput-item-row-11 {
  grid-row: 11;
}
