.GridOutput {
  display: grid;
  grid-column: 1;
  grid-row: 1;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 1fr;
}

.GridOutput-item {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
}

.GridOutput-item-HIR {
  grid-column: 2;
  grid-column-end: 4;
  grid-row: 1;
}

.GridOutput-item-KAT {
  grid-column: 4;
  grid-column-end: 6;
  grid-row: 1;
}

.GridOutput-item-ROM {
  grid-column: 1;
  grid-row: 1;
}

.GridOutput-item-ROM > .GridOutput-item-char {
  grid-column: 1;
  grid-column-end: 3;
}

.GridOutput-item-ROM > .GridOutput-item-script {
  display: none;
}

.GridOutput-item-char {
  align-items: center;
  display: grid;
  justify-items: center;
}

.GridOutput-item-script {
  align-items: center;
  display: grid;
  justify-items: center;
}