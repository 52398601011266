* {
  background-color: transparent;
  border: 0;
  box-sizing: border-box;
  font-family: sans-serif;
  font-size: 1em;
  font-weight: normal;
  margin: 0;
  outline: 0;
  padding: 0;
}

body,
html,
#root {
    height: 100%;
}

button {
  cursor: pointer;
}
